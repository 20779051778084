export default class ImageGallery {
    constructor() {
        this.itemsPerPage = 4;
        this.paginationElement = document.getElementById('pagination');
        this.animationInProgress = false;

        // Pastikan paginationElement tidak null
        if (!this.paginationElement) {
            console.error("Halaman light novel bagus");
        }
    }

    initializePagination() {
        const items = document.querySelectorAll('#content .col-3');
        const totalItems = items.length;

        if (totalItems === 0) {
            return;
        }

        const totalPages = Math.ceil(totalItems / this.itemsPerPage);

        const displayPage = (pageNumber, direction = 'right') => {
            if (this.animationInProgress) return;

            this.animationInProgress = true;
            this.disablePaginationButtons();

            items.forEach((item) => {
                item.style.display = 'none';
                item.classList.remove('slide-in-left', 'slide-in-right');
            });

            const startIdx = (pageNumber - 1) * this.itemsPerPage;
            const endIdx = pageNumber * this.itemsPerPage;
            const pageItems = Array.from(items).slice(startIdx, endIdx);

            pageItems.forEach((item, index) => {
                setTimeout(() => {
                    item.style.display = 'block';
                    item.classList.add(direction === 'right' ? 'slide-in-right' : 'slide-in-left');

                    if (index === pageItems.length - 1) {
                        setTimeout(() => {
                            this.animationInProgress = false;
                            this.enablePaginationButtons();
                        }, 100);
                    }
                }, index * 100);
            });
        };

        const updatePagination = (currentPage, direction = 'right') => {
            if (!this.paginationElement) return;

            this.paginationElement.innerHTML = '';

            // Create "Previous" button
            const prevLi = document.createElement('li');
            prevLi.classList.add('page-item');
            const prevA = document.createElement('a');
            prevA.classList.add('page-link', 'me-3');
            prevA.href = '#';
            prevA.innerHTML = '<';
            prevA.addEventListener('click', (e) => {
                e.preventDefault();
                if (this.animationInProgress) return;
                if (currentPage > 1) {
                    displayPage(currentPage - 1, 'left');
                    updatePagination(currentPage - 1, 'left');
                } else {
                    displayPage(totalPages, 'left');
                    updatePagination(totalPages, 'left');
                }
            });
            prevLi.appendChild(prevA);
            this.paginationElement.appendChild(prevLi);

            // Display current page and total pages
            const pageText = document.createElement('span');
            pageText.classList.add('currentpages');
            pageText.innerHTML = `${currentPage} / ${totalPages}`;
            const pageLi = document.createElement('li');
            pageLi.classList.add('page-item', 'd-flex', 'justify-content-center', 'align-items-center');
            pageLi.appendChild(pageText);
            this.paginationElement.appendChild(pageLi);

            // Create "Next" button
            const nextLi = document.createElement('li');
            nextLi.classList.add('page-item', 'ms-3');
            const nextA = document.createElement('a');
            nextA.classList.add('page-link');
            nextA.href = '#';
            nextA.innerHTML = '>';
            nextA.addEventListener('click', (e) => {
                e.preventDefault();
                if (this.animationInProgress) return;
                if (currentPage < totalPages) {
                    displayPage(currentPage + 1, 'right');
                    updatePagination(currentPage + 1, 'right');
                } else {
                    displayPage(1, 'right');
                    updatePagination(1, 'right');
                }
            });
            nextLi.appendChild(nextA);
            this.paginationElement.appendChild(nextLi);
        };

        displayPage(1, 'right');
        updatePagination(1, 'right');
    }

    disablePaginationButtons() {
        if (!this.paginationElement) return;

        this.paginationElement.querySelectorAll('.page-link').forEach(button => {
            button.style.pointerEvents = 'none';
        });
    }

    enablePaginationButtons() {
        if (!this.paginationElement) return;

        this.paginationElement.querySelectorAll('.page-link').forEach(button => {
            button.style.pointerEvents = 'auto';
        });
    }
}

// Pastikan elemen pagination ada sebelum inisialisasi
document.addEventListener('DOMContentLoaded', () => {
    const gallery = new ImageGallery();
    gallery.initializePagination();
});
