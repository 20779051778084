import Swiper from 'swiper';
import 'swiper/css';
import axios from 'axios';

class Uploaded {
  constructor() {
    this.adjustContent();
    window.addEventListener('resize', this.adjustContent);
    document.addEventListener('DOMContentLoaded', () => {
      const swiper = new Swiper('.mySwiper', {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 1,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });
    });
  }
  adjustContent() {
    // Mengatur teks deskripsi untuk hanya 10 karakter di layar HP
    if (window.innerWidth >= 992) {
    // Pilih semua elemen swiper-slide
    const slides = document.querySelectorAll('.swiper-slide');

    slides.forEach((slide) => {
        // Mengambil elemen deskripsi dan memotong teksnya

        // Menampilkan hanya dua tombol genre
        const buttons = slide.querySelectorAll('#genre button');
        buttons.forEach((btn, index) => {
            if (index >= 6) {
                btn.style.display = 'none';  // Menyembunyikan tombol ketiga dan seterusnya
            }
        });
    });

    } 
    else if (window.innerWidth >= 768) {
      // Mengembalikan teks asli jika lebar layar lebih besar dari 768px
      const swiperSlides = document.querySelectorAll('.swiper-slide');  // Seleksi semua swiper-slide
      swiperSlides.forEach((slide) => {
      
        // Menampilkan semua tombol dalam setiap swiper-slide
        const buttons = slide.querySelectorAll('#genre button');  // Seleksi tombol-tombol dalam setiap slide
        buttons.forEach((btn, index) => {
          if (index >= 4) {
              btn.style.display = 'none';  // Menyembunyikan tombol ketiga dan seterusnya
          }
      });
      });
    } 
    else if (window.innerWidth >= 400) {
      // Mengembalikan teks asli jika lebar layar lebih besar dari 768px
      const swiperSlides = document.querySelectorAll('.swiper-slide');  // Seleksi semua swiper-slide
      swiperSlides.forEach((slide) => {

      
        // Menampilkan semua tombol dalam setiap swiper-slide
        const buttons = slide.querySelectorAll('#genre button');  // Seleksi tombol-tombol dalam setiap slide
        buttons.forEach((btn, index) => {
          if (index >= 2) {
              btn.style.display = 'none';  // Menyembunyikan tombol ketiga dan seterusnya
          }
      });
      });
    } 
    else {
        // Mengembalikan teks asli jika lebar layar lebih besar dari 768px
        const swiperSlides = document.querySelectorAll('.swiper-slide');  // Seleksi semua swiper-slide
        swiperSlides.forEach((slide) => {
        
          // Menampilkan semua tombol dalam setiap swiper-slide
          const buttons = slide.querySelectorAll('#genre button');  // Seleksi tombol-tombol dalam setiap slide
          buttons.forEach((btn, index) => {
            if (index >= 1) {
                btn.style.display = 'none';  // Menyembunyikan tombol ketiga dan seterusnya
            }
        });
        });
    }
  }

  // Fungsi untuk mengambil data menggunakan Axios (contoh)
  fetchData() {
      axios.get('your-api-endpoint-here')
          .then((response) => {
              console.log('Data received:', response.data);
              // Lakukan sesuatu dengan data yang diterima, misalnya update konten
          })
          .catch((error) => {
              console.error('There was an error fetching data:', error);
          });
  }
}

export default Uploaded;
